import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  conceptsMapSelector,
  useConceptLabelFormatter,
  useConceptParentLabelFormatter,
} from 'reducers/entityLabelFormatter';

import { lighten } from 'polished';

import commonPropTypes from 'utils/commonPropTypes';

import { colorPrimary, colorWarning } from 'assets/style/variables';

const colors = [
  [
    lighten(0.59, colorPrimary),
    lighten(0.65, colorPrimary),
    lighten(0.7, colorPrimary),
    lighten(0.73, colorPrimary),
  ],
  [
    lighten(0.32, colorWarning),
    lighten(0.4, colorWarning),
    lighten(0.51, colorWarning),
    lighten(0.6, colorWarning),
  ],
];

const Padded = styled.div`
  padding-bottom: 1rem;
`;

function PredictionTable(props) {
  const {
    predictions,
    predictionsApproval,
    title,
    withVocabulary,
    tablePosition,
    approvalChangeHandler,
    unfoldPredictionApproval,
    disabled,
  } = props;

  const withApprovalColumn = !(
    predictionsApproval === undefined && approvalChangeHandler === undefined
  );
  const conceptLabelFormatter = useConceptLabelFormatter();
  const conceptParentLabelFormatter = useConceptParentLabelFormatter();
  const concepts = useSelector(conceptsMapSelector);
  // Sort acts inplace and we don't want to mutate state from a component so we copy the array.
  const predictionsSorted = PredictionTable.sortPredictions(
    [...predictions],
    concepts
  );
  const colSpan = (withVocabulary ? 5 : 4) + (withApprovalColumn ? 1 : 0);
  return (
    <Padded>
      <Table
        compact
        celled
        size="small"
        style={{ opacity: disabled ? 0.4 : 'inherit' }}
      >
        <Table.Header>
          <Table.Row>
            {title && (
              <Table.HeaderCell colSpan={colSpan}>{title}</Table.HeaderCell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell singleLine>Concept</Table.HeaderCell>
            <Table.HeaderCell>Probability</Table.HeaderCell>
            <Table.HeaderCell>Level</Table.HeaderCell>
            {withVocabulary && <Table.HeaderCell>Vocabulary</Table.HeaderCell>}
            <Table.HeaderCell>Parent</Table.HeaderCell>
            {withApprovalColumn ? (
              <Table.HeaderCell>
                <Button
                  fitted={1}
                  disabled={disabled}
                  icon="square outline"
                  onClick={() => unfoldPredictionApproval()}
                />
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {predictionsSorted.map((pred) => {
            // const level = conceptLevelFormatter(pred.db_concept.id);
            const level = pred.level || 0;
            return (
              <Table.Row
                key={`row-${pred.db_concept.id}`}
                style={{ backgroundColor: colors[tablePosition][level || 0] }}
              >
                <Table.Cell>
                  {conceptLabelFormatter(pred.db_concept.id)}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {pred.probability.toLocaleString('en', { style: 'percent' })}
                </Table.Cell>
                <Table.Cell textAlign="center">{level}</Table.Cell>
                {withVocabulary && (
                  <Table.Cell textAlign="center">
                    {(pred.vocabulary || []).join(', ')}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {conceptParentLabelFormatter(pred.db_concept.id)}
                </Table.Cell>
                {/* The extra column for prediction approval is not mandatory */}
                {withApprovalColumn ? (
                  <Table.Cell>
                    <Checkbox
                      checked={predictionsApproval[pred.db_concept.id] || false}
                      onChange={(e, data) => approvalChangeHandler(data, pred)}
                      disabled={disabled}
                    />
                  </Table.Cell>
                ) : null}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Padded>
  );
}

PredictionTable.propTypes = {
  predictions: PropTypes.arrayOf(
    PropTypes.shape({
      db_concept: PropTypes.shape({ id: PropTypes.string }),
      probability: PropTypes.number,
      vocabulary: PropTypes.string,
    })
  ).isRequired,
  // an object with concept id as keys and the approval status as value.
  predictionsApproval: PropTypes.shape({ 1: PropTypes.bool }),
  approvalChangeHandler: PropTypes.func,
  unfoldPredictionApproval: PropTypes.func,
  tablePosition: PropTypes.number,
  withVocabulary: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    commonPropTypes.i18nText,
  ]),
  disabled: PropTypes.bool,
};

PredictionTable.defaultProps = {
  tablePosition: 0,
  withVocabulary: false,
  title: null,
  predictionsApproval: undefined,
  approvalChangeHandler: undefined,
  unfoldPredictionApproval: undefined,
  disabled: false,
};

PredictionTable.sortPredictions = (predictions, concepts) => {
  // Step 1: Create a map where key is parent id (can be null) and value is the list of children of this parent id.
  const buildHierarchy = (items) => {
    const map = {};
    items.forEach((item) => {
      const concept = concepts[item.db_concept.id];
      const parentId = concept?.parent?.id || null;
      if (!map[parentId]) {
        map[parentId] = [];
      }
      map[parentId].push(item);
    });
    return map;
  };

  const hierarchyMap = buildHierarchy(predictions);
  const sortedPredictions = [];
  const addConceptsInOrder = (parentId) => {
    const children = hierarchyMap[parentId] || [];
    children.sort((a, b) => {
      const levelA = concepts[a.db_concept.id]?.level;
      const levelB = concepts[b.db_concept.id]?.level;
      if (levelA !== levelB) {
        return levelA - levelB;
      }
      return a.db_concept.id.localeCompare(b.db_concept.id);
    });
    children.forEach((child) => {
      sortedPredictions.push({
        ...child,
        level: concepts[child.db_concept.id]?.level,
      });
      addConceptsInOrder(child.db_concept.id, hierarchyMap);
    });
  };

  // Step 2: Initialize the final sorted list empty
  // Iterate on null parent concepts
  const children = hierarchyMap.null || [];
  children.forEach((child) => {
    // Add it to the sorted array
    sortedPredictions.push(child);
    // Add its children one by one, together with their children and so on
    addConceptsInOrder(child.db_concept.id);
  });
  // }
  return sortedPredictions;
};

export default PredictionTable;
